@mixin user-avatar-theme($theme) {
  $background: map-get($theme, background);
  $backgroundColor: mat-color($background, background);

  #avatar-img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .user-status {
    position: absolute;
    bottom: -1px;
    right: -1px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #64dd17;
    border: 2px solid $backgroundColor;
    box-sizing: border-box;
  }

  .small {
    greco-smart-img {
      width: 32px;
      height: 32px;
    }

    .user-status {
      width: 11px;
      height: 11px;
      bottom: 3px;
      right: 3px;
    }
  }
}
