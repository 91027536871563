@import 'libs/core/identity-old/ngx/src/components/user-avatar/user-avatar.component.scss';

@mixin components-theme($theme) {
  @include user-avatar-theme($theme);
}

.flex_form_control {
  display: flex;
  gap: 8px;
}

.flex_form_control_child {
  flex: 1;
}

.mat-tab-container .tabs .mat-tab-link {
  height: 32px;
  margin-bottom: 8px;
}
