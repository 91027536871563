@mixin utilities-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $border: map-get($theme, border);

  body {
    $backgroundColor: mat-color($background, card);
    --background-color: #{$backgroundColor};
    --background-color-light: #f5f5f5;
  }

  .theme-primary {
    --theme-color: var(--primary-color);
    --theme-color-rgb: var(--primary-color-rgb);
  }

  .theme-accent {
    --theme-color: var(--accent-color);
    --theme-color-rgb: var(--accent-color-rgb);
  }

  .theme-warn {
    --theme-color: var(--warn-color);
    --theme-color-rgb: var(--warn-color-rgb);
  }

  .card-bg {
    background-color: mat-color($background, card) !important;
  }

  .light-bg {
    background-color: mat-color($background, hover) !important;
  }

  .primary-bg {
    background-color: mat-color($primary) !important;
  }
  .accent-bg {
    background-color: mat-color($accent) !important;
  }
  .warn-bg {
    background-color: mat-color($warn) !important;
  }
  .dark-bg {
    background-color: #222222 !important;
  }
  .medium-bg {
    background-color: #4d4d4d !important;
  }

  .primary-border {
    border-color: mat-color($primary) !important;
  }
  .accent-border {
    border-color: mat-color($accent) !important;
  }
  .warn-border {
    border-color: mat-color($warn) !important;
  }
  .dark-border {
    border-color: #222222 !important;
  }
  .medium-border {
    border-color: #4d4d4d !important;
  }
  .transparent-border {
    border-color: transparent !important;
  }

  .primary-color {
    color: mat-color($primary) !important;
  }
  .accent-color {
    color: mat-color($accent) !important;
  }
  .warn-color {
    color: mat-color($warn) !important;
  }
  .dark-color {
    color: #222222 !important;
  }

  .mat-zoom {
    background-color: #2d8cff;
    color: white;

    &:not(.mat-button-disabled) {
      box-shadow: 0 3px 6px rgba(var(--zoom-color-rgb), 0.16), 0 3px 6px rgba(var(--zoom-color-rgb), 0.23) !important;
    }
  }
}

.strikethrough {
  text-decoration: line-through !important;
}

.clickable {
  outline: none;
  cursor: pointer;
  // transition: transform 0.2s ease-in-out;
  // image-rendering: auto;
  // &:hover {
  //   will-change: transform;
  //   transform: scale(1.003);
  // }
}

.spin {
  animation: spin 1s infinite linear;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
