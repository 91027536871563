.cdk-drag {
  background-color: var(--background-color, white);
}

.cdk-drag-handle {
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}

.cdk-drop-list-dragging,
.cdk-drop-list-dragging * {
  cursor: grabbing !important;
}

.cdk-drag-preview {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging :not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  display: block;
  opacity: 0;
}
